/* eslint-disable import/prefer-default-export */
export const SOURCES_CAN = {
  ADD_INFANT: ['VJ', 'VU', '1S', '1S_CTRL', 'VN1A', 'VN1A_MT'], // Thêm em bé
  REMOVE_INFANT: ['VJ', 'VN1A', 'VN1A_MT'], // Xoá em bé
}

export const titleOptions = {
  ADULT: [
    {
      value: 'MR',
      text: 'reservation.titleOptions.ADULT.MR',
    },
    {
      value: 'MRS',
      text: 'reservation.titleOptions.ADULT.MRS',
    },
    {
      value: 'MS',
      text: 'reservation.titleOptions.ADULT.MS',
    },
  ],
  CHILD: [
    {
      value: 'MSTR',
      text: 'reservation.titleOptions.CHILD.MSTR',
    },
    {
      value: 'MISS',
      text: 'reservation.titleOptions.CHILD.MISS',
    },
  ],
  INFANT: [
    {
      value: 'MSTR',
      text: 'reservation.titleOptions.INFANT.MSTR',
    },
    {
      value: 'MISS',
      text: 'reservation.titleOptions.INFANT.MISS',
    },
  ],
}

export const languageCode = [
  { key: 'VIETNAMESE', value: 'vi' },
  { key: 'ENGLISH', value: 'en' },
  { key: 'FRENCH', value: 'fr' },
  { key: 'SPANISH', value: 'es' },
  { key: 'PORTUGUESE', value: 'pt' },
  { key: 'ITALIAN', value: 'it' },
  { key: 'GERMAN', value: 'de' },
  { key: 'SWEDISH', value: 'sv' },
  { key: 'GREEK', value: 'el' },
  { key: 'SIMPLIFIED_CHINESE', value: 'zh' },
  { key: 'TRADITIONAL_CHINESE', value: 'zh-Hant' },
  { key: 'JAPANESE', value: 'ja' },
  { key: 'KOREAN', value: 'ko' },
  { key: 'THAI', value: 'th' },
  { key: 'RUSSIAN', value: 'ru' },
  { key: 'POLISH', value: 'pl' },
  { key: 'TAIWAN', value: 'tw' },
]

export const airlinesLogoLong = ['VN', 'QH', 'VU', 'VJ', 'BL']
